import React from "react"
import { Link } from "gatsby";
import { Helmet } from 'react-helmet'
import "@exampledev/new.css/new.css";
import "@exampledev/new.css/theme/boilerplate.css";
import "./custom.css"
import icon from "./images/favicon.ico";
import migration from "./images/migration.png";

export default function Header(props) {

    let og = "";
    if (!props.noog) {
        og = [
            <meta key="head1" name="theme-color" content="#000000" />,
            <meta key="head2" property="og:title" content="John Haldeman - Personal Site" />,
            <meta key="head3" property="og:description" content="John Haldeman's Projects, Visualizations, and Blog" />,
            <meta key="head4" property="og:image" content={migration} />,
            <meta name="twitter:card" content="summary_large_image" />,
            <meta name="twitter:creator" content="@JLHaldeman" />,
            <meta name="twitter:title" content="John Haldeman - Personal Site" />,
            <meta name="twitter:description" content="John Haldeman's Projects, Visualizations, and Blog" />,
            <meta name="twitter:image" content={migration} ></meta>,
        ]
    }
    else{
        og = [
            <meta key="head1" name="theme-color" content="#000000" />,
            <meta key="head2" property="og:title" content={props.title}/>,
            <meta key="head3" property="og:description" content={props.description} />,
            <meta key="head4" property="og:image" content={props.image} />,
            <meta name="twitter:card" content="summary_large_image" />,
            <meta name="twitter:creator" content="@JLHaldeman" />,
            <meta name="twitter:title" content={props.title} />,
            <meta name="twitter:description" content={props.description} />,
            <meta name="twitter:image" content={props.image} ></meta>,
        ]
    }

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>John Haldeman - {props.title}</title>
                <link rel="icon" href={icon} />
                <link rel="alternate" type="application/atom+xml" href="http://johnhaldeman.ca/atomfeed.xml" />
                {og}
                <script async src="https://www.googletagmanager.com/gtag/js?id=UA-167518648-1"></script>
                <script>
                    {`
                    
                      window.dataLayer = window.dataLayer || [];
                      function gtag(){dataLayer.push(arguments);}
                      gtag('js', new Date());
                    
                      gtag('config', 'UA-167518648-1');
                    
                    `}
                </script>
            </Helmet>
            <header>
                <h2>John Haldeman - {props.title}</h2>
                <Link to="/">Home</Link> | <Link to="projects">Projects</Link> | <Link to="visualizations">Visualizations</Link> | <Link to="presentations">Presentations</Link> | <Link to="blog">Blog</Link>
            </header>
        </>
    )
}